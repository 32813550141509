import styled from 'styled-components';
import { colours, spacing } from '../../../assets/theme';
import { rgba } from 'polished';

const Fade = styled.div`
  width: 100vw;
  height: ${spacing[3]}px;
  background: linear-gradient(
    ${rgba(colours.black, 0)} 33%,
    ${props => props.theme?.background ?? colours.black} 100%
  );
  transition: background 0.3s ease-in-out;
  pointer-events: none;
  left: 0;
`;

export default Fade;
