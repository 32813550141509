import { isBefore, format, isToday, isTomorrow, addDays } from 'date-fns';
import { fr, enGB } from 'date-fns/locale';
import { capitaliseFirstLetter } from '../../../helpers/string';

export const getScheduleFromData = data => data?.allStrapiUpcomingShow.nodes
  ?.filter(item => {
    const { broadcast_start_time: broadcastStartTime, broadcast_end_time: broadcastEndTime } = item;
    const today = format(new Date(), 'yyyy/M/d');
    const startDay = format(new Date(broadcastStartTime), 'yyyy/M/d');
    const datestring = `${startDay} ${broadcastEndTime?.substring(0, 5)}`;
    let broadcastEnd = new Date(datestring);
    if (isBefore(broadcastEnd, new Date(broadcastStartTime))) {
      broadcastEnd = addDays(broadcastEnd, 1);
    }
    return !isBefore(broadcastEnd, new Date(today)) && item.active;
  })
  .reduce((obj, item) => {
    const newObject = obj;
    const { broadcast_start_time: broadcastStartTime } = item;
    const startTime = format(new Date(broadcastStartTime), 'yyyy-MM-dd');

    if (newObject[startTime]) {
      newObject[startTime] = [...newObject[startTime], item];
    } else {
      newObject[startTime] = [item];
    }

    return newObject;
  }, {});

export const formatDay = (dateTime, lang) => {
  const locale = lang === 'fr' ? fr : enGB;

  const date = new Date(dateTime);
  if (isToday(date)) return lang === 'fr' ? "Aujourd'hui" : 'Today';
  if (isTomorrow(date)) return lang === 'fr' ? 'Demain' : 'Tomorrow';
  return capitaliseFirstLetter(
    format(date, lang === 'fr' ? 'cccc, d MMMM' : 'cccc, do MMMM', { locale }) || '',
  );
};
