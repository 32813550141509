import styled from 'styled-components';
import { mediaQuery, spacing } from '../../../assets/theme';

export const Image = styled.div`
    height: 20vh;
    background-image: url(${props => props.src});
    background-size: cover;
    margin-bottom: ${spacing[3]}px;
`;

export const Li = styled.li`
    width: 75%;
    margin-bottom: ${spacing[6]}px;
    ${mediaQuery.lessThan('md')`
        width: 100%;
`}
`;

export const GridRow = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
`;
