import React from 'react';
import { withTheme } from 'styled-components';

import Page from '../../organisms/Page';
import ContentsWrapper from '../../Layout/ContentsWrapper';
import { P, H3, H2, Label } from '../../atoms/Text';

import { spacing } from '../../../assets/theme';
import { useLangStore } from '../../../store';

import { formatDay } from '../../organisms/Schedule/helpers';
import Link from '../../atoms/Link';
import { graphql, useStaticQuery } from 'gatsby';
import { Columns } from '../about/styles';
import { Image, Li, GridRow } from './styles';

const PostsPage = () => {
  const lang = useLangStore(store => store.currentLang);

  const {
    allStrapiAnnouncements: { nodes: announcements },
    newsPage,
  } = useStaticQuery(
    graphql`
      query announcementsQuery {
        allStrapiAnnouncements(filter: { publishToWebsite: { eq: true } }) {
          nodes {
            title_en
            title_fr
            excerpt_fr
            excerpt_en
            fields {
              slug
            }
            image {
              publicURL
            }
            published_at
          }
        }

        newsPage: strapiNewsPage {
          title_en
          title_fr
          page_meta {
            page_title_fr
            page_title_en
          }
        }
      }
    `,
  );

  return (
    <Page title={{ en: newsPage.page_meta?.page_title_en, fr: newsPage.page_meta?.page_title_fr }} metaImg="">
      <ContentsWrapper>
        <Columns>
          <div>
            <H2
              css={`
                margin-bottom: ${spacing[4]}px;
              `}
            >
              {newsPage[`title_${lang}`]}
            </H2>
            <ul>
              {announcements.map(post => (
                <Li data-hoverable>
                  <Link to={`/${post.fields.slug}`}>
                    <Image src={post.image?.publicURL} />
                    <GridRow justify="space-between">
                      <div>
                        <H3
                          size={24}
                          css={`
                            margin-bottom: ${spacing[2]}px;
                          `}
                        >
                          {post[`title_${lang}`]}
                        </H3>
                        <P size={18}>{post[`excerpt_${lang}`]}</P>
                      </div>

                      <Label>{formatDay(post.published_at, lang)}</Label>
                    </GridRow>
                  </Link>
                </Li>
              ))}
            </ul>
          </div>
        </Columns>
      </ContentsWrapper>
    </Page>
  );
};

PostsPage.propTypes = {
  // pageContext: shape({
  //   host: { Name: hostName, image },
  //   slug,
  // }),
};

export default PostsPage;
