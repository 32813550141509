import styled from 'styled-components';
import MarkdownRenderer from '../../atoms/Markdown';
import FadeBlock from '../../atoms/Fade';

import { mediaQuery } from '../../../assets/theme';
import { P } from '../../atoms/Text';
import { LinkTag } from '../../atoms/Tag';
import Link from '../../atoms/Link';

export const Columns = styled.div`
  display: grid;
  grid-template-columns: ${props => props.templateColumns || '1.2fr 1fr'};
  grid-column-gap: 32px;
  padding-bottom: 64px;

  ${mediaQuery.lessThan('lg')`
      grid-template-columns: 1fr;
  `};
  ${mediaQuery.lessThan('md')`
      padding-top: 32px;
      padding-bottom: 16px;
  `};

  .newsletter-form {
    margin: 32px 0 32px 16px;

    ${mediaQuery.lessThan('md')`
      margin: 16px 0 32px 0;
    `}
  }
`;

export const Markdown = styled(MarkdownRenderer)`
  ${mediaQuery.lessThan('md')`
      padding-top: 32px;
      padding-bottom: 16px;
  `};
`;

export const LinkBox = styled.a`
  border: 1px solid ${({ theme }) => theme.foreground};
`;

export const Fade = styled(FadeBlock)`
  position: fixed;
  left: 0;
  bottom: 50px;
  width: 100vw;
`;

export const TextBlock = styled(P)`
  display: inline-block;
  padding: 0 16px 12px 16px;
  font-family: monospace;
  margin-bottom: 16px;
  ${mediaQuery.greaterThan('md')`
    margin-left: 16px;
  `}

  p {
    margin: 0;
    padding: 0;
    font-family: monospace;
  }
`;

export const BoxLink = styled(Link)`
  display: inline-block;
  padding: 12px 16px;
  border: 2px solid ${({ theme }) => theme.highlight};
  font-family: monospace;
  margin-bottom: 16px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  background: ${({ theme }) => theme.highlight};

  span {
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.foreground};
    background: ${({ theme }) => theme.foreground};

    span {
      color: ${({ theme }) => theme.background};
    }
  }

  ${mediaQuery.greaterThan('md')`
    margin-left: 16px;
  `}
`;

export const StyledTagLink = styled(LinkTag)`
  margin-bottom: 16px;
  ${mediaQuery.greaterThan('md')`
    margin-left: 16px;
  `}
  ${mediaQuery.lessThan('md')`
    margin-right: 8px;
    margin-bottom: 12px;
  `}
`;
